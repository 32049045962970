const downloadFile = (data, fileName, fileType) => {
  const blob = new Blob([data], { type: fileType });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);

  document.body.appendChild(link);
  link.click();

  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const downloadCSV = (data, fileName) => {
  downloadFile(data, `${fileName}.csv`, "text/csv");
};
