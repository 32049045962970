import { Select } from "antd";

export const TextParameter = ({ value, options, onChange }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
      showSearch
      optionFilterProp="label"
      virtual={true}
    />
  );
};
