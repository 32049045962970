import {
  deleteAPI,
  getAPI,
  postAPI,
} from "../../../../../../../common/api/api";

const PATH = "catman/product/brand/trademark";

export const fetchTrademarks = async (lang) => getAPI(PATH);

export const fetchTrademark = async (id) => getAPI(`${PATH}/${id}`);

export const persistTrademark = async (body) => postAPI(PATH, body);

export const deleteTrademark = async (id) => deleteAPI(`${PATH}/${id}`);
