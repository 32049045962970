import { useTranslation } from "react-i18next";
import { Button, Form, notification, Select, Space, Upload } from "antd";
import { useParams } from "react-router-dom";
import {
  CloseOutlined,
  DownloadOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { downloadCSV } from "../../../../../../common/file/file";

export const BulkAdd = () => {
  const PRODUCT = "product";

  const { t } = useTranslation("catman");
  const [api, contextHolder] = notification.useNotification();
  const { lang } = useParams();

  const columns = {
    [PRODUCT]: [
      t("categoryName"),
      t("brandName"),
      t("trademarkName"),
      t("ean"),
      t("mpn"),
      t("groupParametersName"),
      t("parameterName"),
      t("parameterOptionName"),
    ],
  };

  const [input, setInput] = useState({
    bulkOption: null,
    data: null,
  });

  const readFile = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });

  const parseCSV = (data) =>
    data
      ?.split(/\r\n+/g)
      ?.map((row) =>
        row
          .match(/("([^"]|"")*"|[^,]+)/g)
          ?.map((match) => match.replace(/(^"|"$)/g, "").replace(/""/g, '"')),
      ) || [];

  const transformData = (data, option) =>
    data.map((row, rowIndex) => {
      let idx = 0;
      return {
        key: rowIndex,
        ...columns[option].reduce(
          (acc, column) => ({
            ...acc,
            [column]: row[idx++],
          }),
          {},
        ),
      };
    }) || [];

  const bulkOptionOnSelect = (value) => {
    setInput({
      ...input,
      bulkOption: value,
      data: null,
    });
  };

  const bulkOptionOnSearch = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const optionOnClear = () => {
    setInput({
      ...input,
      bulkOption: null,
    });
  };

  const fileOnRemove = () => {
    setInput({ ...input, data: null });
  };

  const fileOnBeforeUpload = async (file) => {
    const isCsv = file.type === "text/csv";

    if (isCsv) {
      setInput({
        ...input,
        data: transformData(
          parseCSV(await readFile(file))?.slice(1),
          input.bulkOption,
        ),
      });

      return false;
    }

    return isCsv || Upload.LIST_IGNORE;
  };

  const downloadOnClick = () => {
    downloadCSV(columns[input.bulkOption].join(","), `${t(input.bulkOption)}`);
  };

  return (
    <Form layout="vertical">
      <Form.Item>
        <Space.Compact style={{ width: "100%" }}>
          <Select
            showSearch
            placeholder={t("bulkAdd")}
            onChange={bulkOptionOnSelect}
            onSearch={bulkOptionOnSearch}
            allowClear={{
              clearIcon: <CloseOutlined onClick={optionOnClear} />,
            }}
            options={[
              {
                value: PRODUCT,
                label: t(PRODUCT),
              },
            ]}
            style={{ width: "100%" }}
          />
          <Button
            icon={<DownloadOutlined />}
            title={t("download")}
            onClick={downloadOnClick}
            disabled={!input.bulkOption}
          />
        </Space.Compact>
      </Form.Item>
      {input.bulkOption && (
        <Form.Item>
          <Upload.Dragger
            onRemove={fileOnRemove}
            beforeUpload={fileOnBeforeUpload}
            multiple={false}
            maxCount={1}
          >
            <InboxOutlined />
          </Upload.Dragger>
        </Form.Item>
      )}
    </Form>
  );
};
