import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { usePreferences } from "../store/globalStore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { languageFlags } from "./lang";

export default function Language() {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const { language, setLanguage } = usePreferences();

  const [input, setInput] = useState({
    languages: [],
  });

  useEffect(() => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    setInput({
      ...input,
      languages: languageFlags.map((item) => ({
        ...item,
        label: `${item.label} ${item.title}`,
      })),
    });
  }, []);

  const updateLanguage = (language) => {
    navigate(window.location.pathname.replace(`/${lang}`, `/${language}`));
    setLanguage(language);
    i18n.changeLanguage(language);
  };

  return (
    <Select
      value={language.value}
      options={input.languages}
      onChange={updateLanguage}
      style={{ width: "140px" }}
    />
  );
}
