import {
  Button,
  Cascader,
  Form,
  Input,
  notification,
  Segmented,
  Select,
  Space,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import { usePreferences } from "../../../../../../store/globalStore";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  deleteCategory,
  fetchCategories,
  fetchCategory,
  persistCategory,
} from "./api/category";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { fetchCpvs } from "./api/cpv";
import { fetchUnits } from "./api/units";
import { error, success } from "../../../../../../common/message/notification";

export default function ProductCategorySection() {
  const NEW = "new";
  const EXISTING = "existing";

  const { i18n } = useTranslation();
  const [t] = useTranslation("catman");
  const { lang } = useParams();
  const { language } = usePreferences();
  const [api, contextHolder] = notification.useNotification();

  const [input, setInput] = useState({
    id: null,
    existingId: null,
    status: NEW,
    cpvs: [],
    cpvId: [],
    units: [],
    unitId: [],
    options: [],
    translation: {
      language: language.value,
      name: {},
    },
  });

  function findParentByChildId(items, childId, parent = null) {
    return items.reduce(
      (found, item) =>
        found ||
        (item.value === childId
          ? parent
          : item.children
            ? findParentByChildId(item.children, childId, item)
            : null),
      null,
    );
  }

  const initInput = async () => {
    setInput({
      ...input,
      options: (await fetchCategories(lang)) || [],
      cpvs: (await fetchCpvs(lang)) || [],
      units: (await fetchUnits(lang)) || [],
    });
  };

  useEffect(() => {
    setInput({
      ...input,
      translation: {
        ...input.translation,
        language: language.value,
      },
    });
  }, [language]);

  useEffect(() => {
    initInput();
  }, []);

  const filterTreeNode = (input, node) => {
    return node?.title?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const searchByLabel = (input, data) => {
    return data.some(
      (option) => option.label.toLowerCase().indexOf(input.toLowerCase()) > -1,
    );
  };

  const categoryStatusOnChange = (value) => {
    setInput({
      ...input,
      status: value,
      id: null,
      existingId: null,
      cpvId: [],
      unitId: [],
      translation: {
        language: language.value,
        name: {},
      },
    });
  };

  const traverseTree = (data, source) => {
    return data.map((item) => ({
      ...item,
      disabled: item.type === source,
      children: item?.children ? traverseTree(item?.children, source) : [],
    }));
  };

  const existingCategoryOnChange = async (value) => {
    const category = (await fetchCategory(value?.split("|")[1])) || {};

    let unitId = [];
    for (const unit of input.units) {
      if (category?.unit_id === unit.value) {
        unitId = [unit.value, unit.value];
        break;
      }

      for (const child of unit?.children) {
        if (category?.unit_id === child.value) {
          unitId = [unit.value, child.value];
          break;
        }
      }
    }

    setInput({
      ...input,
      existingId: value,
      id: findParentByChildId(
        input.options,
        `${category.navigation_id}|${category.id}`,
      )?.value,
      cpvId: [category?.cpv_id] || [],
      unitId: unitId,
      translation: {
        ...input.translation,
        name: category?.translation || {},
      },
    });
  };

  const currentCategoryOnChange = (value) => {
    setInput({
      ...input,
      id: value,
    });
  };

  const categoryNameTranslationOnChange = (value) => {
    setInput({
      ...input,
      translation: {
        ...input.translation,
        language: value,
      },
    });
  };

  const categoryNameTranslationInputOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      translation: {
        ...input.translation,
        name: {
          ...input.translation.name,
          [input.translation.language]: value,
        },
      },
    });
  };

  const unitOnChange = (value) => {
    setInput({
      ...input,
      unitId: value,
    });
  };

  const cpvCodeOnChange = (value) => {
    setInput({
      ...input,
      cpvId: value,
    });
  };

  const deleteCategoryOnClick = async () => {
    try {
      await deleteCategory(input.existingId?.split("|")[1]);
      success(api, t("success"), t("deleteCategory"));
      setInput({
        ...input,
        options: (await fetchCategories(lang)) || [],
      });
    } catch (e) {
      error(api, t("error"), t("deleteCategory"));
      console.log("Delete", e.message);
    }
  };

  const saveCategoryOnClick = async () => {
    try {
      const [_, existingId] = input.existingId?.split("|") || [];
      const [__, id] = input.id?.split("|") || [];

      await persistCategory({
        navigation_id: id || 0,
        id: existingId,
        cpv_id: input.cpvId.at(-1),
        unit_id: input.unitId.at(-1),
        translation: {
          ...input.translation.name,
        },
      });
      success(api, t("success"), t("persistCategory"));
      setInput({
        ...input,
        existingId: `${id || 0}|${existingId}`,
        options: (await fetchCategories(lang)) || [],
      });
    } catch (e) {
      error(api, t("error"), t("persistCategory"));
      console.log("Save", e.message);
    }
  };

  return (
    <Form layout="vertical">
      {contextHolder}
      <Form.Item>
        <Space.Compact style={{ width: "100%" }}>
          <Segmented
            value={input.status}
            options={[
              {
                value: NEW,
                label: t(NEW),
              },
              {
                value: EXISTING,
                label: t(EXISTING),
              },
            ]}
            onChange={categoryStatusOnChange}
          />
          {input.status === EXISTING && (
            <TreeSelect
              treeData={traverseTree(input.options, "NAVIGATION")}
              value={input.existingId}
              onChange={existingCategoryOnChange}
              filterTreeNode={filterTreeNode}
              placeholder={t("categoryName")}
              showSearch
              allowClear
              treeDefaultExpandAll
              style={{
                width: "100%",
              }}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
            />
          )}
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("parentNavigationSection")}>
        <Space.Compact style={{ width: "100%" }}>
          <TreeSelect
            treeData={traverseTree(input.options, "CATEGORY")}
            value={input.id}
            onChange={currentCategoryOnChange}
            filterTreeNode={filterTreeNode}
            placeholder={t("parentNavigationSection")}
            showSearch
            allowClear
            treeDefaultExpandAll
            style={{
              width: "100%",
            }}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
          />
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("categoryName")}>
        <Space.Compact style={{ width: "100%" }}>
          <Select
            value={input.translation.language}
            options={[
              {
                value: "de",
                label: "🇩🇪",
              },
              {
                value: "en",
                label: "🇺🇸",
              },
              {
                value: "pl",
                label: "🇵🇱",
              },
              {
                value: "ua",
                label: "🇺🇦",
              },
            ]}
            onChange={categoryNameTranslationOnChange}
            style={{ width: "auto" }}
            virtual
          />
          <Input
            value={input.translation.name[input.translation.language]}
            onChange={categoryNameTranslationInputOnChange}
            placeholder={t("categoryName")}
            style={{ width: "100%" }}
          />
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("measurementUnit")}>
        <Cascader
          expandTrigger="hover"
          value={input.unitId}
          options={input.units}
          onChange={unitOnChange}
          placeholder={t("measurementUnit")}
          showSearch={{
            filter: searchByLabel,
          }}
        />
      </Form.Item>
      <Form.Item label={t("purchasingClassifierCode")}>
        <Select
          value={input.cpvId}
          options={input.cpvs}
          onChange={cpvCodeOnChange}
          placeholder={t("purchasingClassifierCode")}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item>
        <Space.Compact className="form_buttons-action">
          {input.existingId && (
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={deleteCategoryOnClick}
            >
              {t("delete")}
            </Button>
          )}
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={saveCategoryOnClick}
          >
            {t("save")}
          </Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
}
