import { Breadcrumb, Tabs } from "antd";
import React, { useState } from "react";
import "./Category.css";
import NavigationSection from "./components/navigationSection/NavigationSection";
import ProductCategorySection from "./components/productCategorySection/ProductCategorySection";
import { GroupParameters } from "./components/groupParameters/GroupParameters";
import { useTranslation } from "react-i18next";
import CategoryManagement from "./components/categoryManagement/CategoryManagement";
import { useNavigate, useParams } from "react-router-dom";
import Parameter from "./components/parameter/Parameter";
import { ParameterOption } from "./components/paremeterOption/ParameterOption";
import { BulkAdd } from "./components/bulkAdd/BulkAdd";
import { catmanGroup } from "../../../../routes/routes";

export default function Category() {
  const [t] = useTranslation("catman");

  const params = useParams();
  const navigate = useNavigate();

  const [input, setInput] = useState({
    navigation: params.subGroup || "navigation",
  });

  const navigationOnChange = (key) => {
    setInput({ ...input, navigation: key });
    navigate(catmanGroup(params) + `/${key}`);
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: t("catalog"),
          },
          {
            title: t("category"),
          },
        ]}
      />
      <Tabs
        activeKey={input.navigation}
        onChange={navigationOnChange}
        items={[
          {
            key: "navigation",
            label: t("navigationSection"),
            children: <NavigationSection />,
          },
          {
            key: "product",
            label: t("productCategory"),
            children: <ProductCategorySection />,
          },
          {
            key: "group-parameters",
            label: t("groupParameters"),
            children: <GroupParameters />,
          },
          {
            key: "parameters",
            label: t("parameters"),
            children: <Parameter />,
          },
          {
            key: "parameter-options",
            label: t("parameterOptions"),
            children: <ParameterOption />,
          },
          {
            key: "bulk-add",
            label: t("bulkAdd"),
            children: <BulkAdd />,
          },
          {
            key: "category-management",
            label: t("categoryManagement"),
            children: <CategoryManagement />,
          },
        ]}
      />
    </>
  );
}
