import { InputNumber } from "antd";

export const NumberParameter = ({ value, onChange, min, max }) => {
  return (
    <InputNumber
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      step="0.5"
    />
  );
};
