export const languageFlags = [
  {
    value: "bg",
    label: "🇧🇬",
    title: "Български",
  },
  {
    value: "cs",
    label: "🇨🇿",
    title: "Čeština",
  },
  {
    value: "da",
    label: "🇩🇰",
    title: "Dansk",
  },
  {
    value: "de",
    label: "🇩🇪",
    title: "Deutsch",
  },
  {
    value: "el",
    label: "🇬🇷",
    title: "Ελληνικά",
  },
  {
    value: "en",
    label: "🇺🇸",
    title: "English",
  },
  {
    value: "es",
    label: "🇪🇸",
    title: "Español",
  },
  {
    value: "et",
    label: "🇪🇪",
    title: "Eesti",
  },
  {
    value: "fi",
    label: "🇫🇮",
    title: "Suomi",
  },
  {
    value: "fr",
    label: "🇫🇷",
    title: "Français",
  },
  {
    value: "hr",
    label: "🇭🇷",
    title: "Hrvatski",
  },
  {
    value: "hu",
    label: "🇭🇺",
    title: "Magyar",
  },
  {
    value: "it",
    label: "🇮🇹",
    title: "Italiano",
  },
  {
    value: "lt",
    label: "🇱🇹",
    title: "Lietuvių",
  },
  {
    value: "lv",
    label: "🇱🇻",
    title: "Latviešu",
  },
  {
    value: "mt",
    label: "🇲🇾",
    title: "Malti",
  },
  {
    value: "nl",
    label: "🇳🇱",
    title: "Nederlands",
  },
  {
    value: "pl",
    label: "🇵🇱",
    title: "Polski",
  },
  {
    value: "pt",
    label: "🇵🇹",
    title: "Português",
  },
  {
    value: "ro",
    label: "🇷🇴",
    title: "Română",
  },
  {
    value: "sk",
    label: "🇸🇰",
    title: "Slovak",
  },
  {
    value: "sl",
    label: "🇸🇮",
    title: "Slovenski",
  },
  {
    value: "sv",
    label: "🇸🇪",
    title: "Svenska",
  },
  {
    value: "ua",
    label: "🇺🇦",
    title: "Українська",
  },
];
