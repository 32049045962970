import React, { useContext, useMemo } from "react";
import {
  ControlOutlined,
  EyeInvisibleOutlined,
  FileDoneOutlined,
  HolderOutlined,
} from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Popover, Table } from "antd";
import { useTranslation } from "react-i18next";

export default function CategoryManagement() {
  const { t } = useTranslation();

  const columns = [
    {
      key: "sort",
      align: "center",
      render: () => <DragHandle />,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
  ];
  const initialData = [
    {
      key: "1",
      status: (
        <Popover content={t("draft")}>
          <ControlOutlined style={{ color: "#2998ff" }} />
        </Popover>
      ),
      name: "Pen",
    },
    {
      key: "2",
      status: (
        <Popover content={t("published")}>
          <FileDoneOutlined style={{ color: "#7de749" }} />
        </Popover>
      ),
      name: "Shredder",
    },
    {
      key: "3",
      status: (
        <Popover content={t("hidden")}>
          <EyeInvisibleOutlined style={{ color: "#f6343e" }} />
        </Popover>
      ),
      name: "Paper",
    },
  ];

  const [dataSource, setDataSource] = React.useState(initialData);

  const RowContext = React.createContext({});
  const DragHandle = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
      <Button
        type="text"
        size="small"
        icon={<HolderOutlined />}
        style={{
          cursor: "move",
        }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    );
  };

  const Row = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    const contextValue = useMemo(
      () => ({
        setActivatorNodeRef,
        listeners,
      }),
      [setActivatorNodeRef, listeners],
    );
    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.key === active?.id,
        );
        const overIndex = prevState.findIndex(
          (record) => record.key === over?.id,
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };
  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={dataSource.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          rowKey="key"
          components={{
            body: {
              row: Row,
            },
          }}
          columns={columns}
          dataSource={dataSource}
          size="small"
        />
      </SortableContext>
    </DndContext>
  );
}
